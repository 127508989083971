import React, {Component} from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/styles";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import {createStyles, Theme, WithStyles} from "@material-ui/core";
import {auth} from '../config'
import {inject, observer} from "mobx-react";
import {AppStore} from "../stores/AppStore";
import { HOME } from "../constants/Routes";

const styles = (theme: Theme) => createStyles({
    "@global": {
        body: {
            backgroundColor: "#fff"
        }
    },
    paper: {
        marginTop: 100,
        display: "flex",
        padding: 20,
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#f50057"
    },
    form: {
        marginTop: 1
    },
    errorText: {
        color: "#f50057",
        marginBottom: 5,
        textAlign: "center"
    }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    appStore: AppStore;
}

interface State {
    email: string;
    password: string;
    errorMessage: string;
}

@inject('appStore')
@observer
class SignIn extends Component<Props, State> {
    state = {email: "", password: "", errorMessage: ""};

    handleEmailChange = ({target}) => {
        this.setState({email: target.value});
    };

    handlePasswordChange = ({target}) => {
        this.setState({password: target.value});
    };

    handleSignIn = async () => {
        const {email, password} = this.state;
        this.setState({errorMessage: ""});
        try {
            await auth.signInWithEmailAndPassword(email, password);
            this.props.history.push(HOME)
        } catch (error) {
            this.setState({errorMessage: error.message})
        }
    }

    render() {
        const {classes, appStore} = this.props;
        const {errorMessage} = this.state;

        if (appStore.isAuthenticated) {
            return <Redirect to="/"/>;
        } else {
            return (
                <Container component="main" maxWidth="xs">
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h6">
                            Sign in
                        </Typography>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={this.handleEmailChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={this.handlePasswordChange}
                        />
                        {errorMessage && (
                            <Typography component="p" className={classes.errorText}>
                                Incorrect email or password.
                            </Typography>
                        )}
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSignIn}
                        >
                            Sign In
                        </Button>
                    </Paper>
                </Container>
            );
        }
    }
}

export default withStyles(styles)(withRouter(SignIn));
