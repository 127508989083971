import { Coupon } from '../models/Payment';
import { db } from "../config";
import { observable } from "mobx";

const approvedCompanyPath = 'approved-company';
const couponPath = 'coupons';

export class CompanyCouponsStore {

    @observable public coupons: Coupon[] | undefined;

    async getCoupons(salonId: string): Promise<void> {
        const companies = await db.collection(approvedCompanyPath).doc(salonId).collection(couponPath)
            .orderBy('creationTimestamp')
            .get();

        this.coupons = companies.docs
            .filter(c => c.data().processed !== true)
            .map(d => ({...d.data(), id: d.id} as Coupon));
    }

}

const companyCouponsStore = new CompanyCouponsStore();
export default companyCouponsStore;
