import React from 'react';

import appStore from '../stores/AppStore';
import { Redirect } from 'react-router-dom';
import {SIGN_IN} from "../constants/Routes";

const protectedRoute = (RouteComponent) => {
    if (appStore.isAuthenticated) {
        return <RouteComponent />;
    }
    return <Redirect to={SIGN_IN} />;
};

export default protectedRoute;
