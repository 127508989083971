import React from 'react';
import { withStyles, AppBar, WithStyles, Toolbar, Grid, Button, withWidth, WithWidthProps, Hidden, IconButton, MenuItem, Popover } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import to from './to';
import { COMPANY_DASHBOARD, FIND_BUSINESS, HOME, SIGN_IN, CONTACT, FAQS, SALONS } from '../constants/Routes';
import { inject, observer } from "mobx-react";
import appStore, { AppStore } from "../stores/AppStore";

const styles = {
    root: {},
    toolBarStyle: {
        minHeight: 50,
        paddingTop: 20,
        paddingRight: 32,
        paddingBottom: 20,
        paddingLeft: 32,
    },
}

interface HeaderProps extends WithStyles<typeof styles>, RouteComponentProps, WithWidthProps {
    appStore?: AppStore;
}

interface HeaderState {
    anchorEl,
}

const menuId = 'primary-search-account-menu';

@inject('appStore')
@observer
class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
    }

    handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleMenuClose = (goTo?: string) => {
        this.setState({
            anchorEl: null
        })
        if (!!goTo) this.props.history.push(goTo);
    };

    handleSignOut = () => {
        appStore.signOut();
        this.props.history.push(HOME);
    }

    render() {
        const { classes, appStore } = this.props;
        return (
            <div>
                <AppBar position='static' elevation={0}>
                    <Toolbar className={classes.toolBarStyle}>
                        <Grid container justify='center' alignItems='center' spacing={3}>
                        <NavLink strict to={HOME} style={{ textDecoration: 'none'}} >
                            <Grid item xs={2} container direction='column' style={{ minWidth: 162 }}>
                                <span style={{
                                    color: '#2d2d2d', 
                                    fontFamily: 'PlayfairDisplay',
                                    fontSize: 22,
                                    fontWeight: "bold",
                                    fontStyle: "normal",
                                    letterSpacing: -0.53
                                }}>Save your salons</span>
                                <span style={{
                                    color: '#5C65DF',
                                    fontFamily: 'Lato',
                                    fontSize: 11,
                                    fontWeight: "bold", 
                                    fontStyle: "normal",
                                    letterSpacing: -0.05,
                                    marginTop: -2 
                                }}>Sponsored Glammly</span>
                            </Grid>
                            </NavLink>
                            <Hidden smDown>
                                <Grid item xs container justify={'flex-start'} alignItems='center' spacing={2}>
                                    <Grid item>
                                        <Button size="medium" color="secondary" variant="text" component={NavLink} {...to(SALONS)}>Salons</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="medium" color="secondary" variant="text" component={NavLink} {...to(FAQS)}>FAQ's</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="medium" color="secondary" variant="text" component={NavLink} {...to(CONTACT)}>Contact</Button>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Grid item xs container justify={'flex-end'} alignItems='center' spacing={1}>
                                {/* <Hidden xsDown>
                                    <Grid item>
                                        <Button size="medium" color='secondary' variant='outlined' component={NavLink} {...to(HOME)}>Share</Button>
                                    </Grid>
                                </Hidden> */}
                                <Grid item>
                                    <Button size="medium" color='primary' variant='contained' component={NavLink} {...to(FIND_BUSINESS)}>Add Local Business</Button>
                                </Grid>
                                <Hidden smDown>
                                    <Grid item>
                                        {!appStore!.isAuthenticated &&
                                            <Button color='secondary' variant='contained' component={NavLink} {...to(SIGN_IN)}>Sign In</Button>
                                        }

                                        {appStore!.isAuthenticated &&
                                            <Button color='secondary' variant='contained' onClick={this.handleSignOut} >Sign Out</Button>
                                        }
                                    </Grid>
                                    {appStore!.isAuthenticated &&
                                        <Grid item>
                                            <Button color='secondary' variant='contained' component={NavLink} {...to(COMPANY_DASHBOARD)}>Dashboard</Button>
                                        </Grid>
                                    }
                                </Hidden>
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={1} container justify={'flex-start'} alignItems='center' spacing={2}>
                                    <IconButton edge="start" color="inherit"
                                        aria-label="menu"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        onClick={this.handleProfileMenuOpen}>
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Popover
                    anchorEl={this.state.anchorEl}
                    id={menuId}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={!!this.state.anchorEl}
                    onClose={this.handleMenuClose}
                >
                    <MenuItem onClick={() => this.handleMenuClose(HOME)}>Home</MenuItem>
                    <MenuItem onClick={() => this.handleMenuClose(SALONS)}>Salons</MenuItem>
                    <MenuItem onClick={() => this.handleMenuClose(FAQS)}>FAQ's</MenuItem>
                    {/* <Hidden smUp>
                        <MenuItem onClick={this.handleMenuClose}>Share</MenuItem>
                    </Hidden>*/}
                    <MenuItem onClick={() => this.handleMenuClose(CONTACT)}>Contact</MenuItem> 
                    <MenuItem onClick={() => this.handleMenuClose(SIGN_IN)}>Sign In</MenuItem>
                    {appStore!.isAuthenticated &&
                        <Grid item>
                            <Button color='secondary' variant='contained' component={NavLink} {...to(COMPANY_DASHBOARD)}>Dashboard</Button>
                        </Grid>
                    }
                </Popover>
            </div>
        )
    }
}

export default withWidth()(withStyles(styles)(withRouter(Header)));
