import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { Router } from 'react-router';
import * as history from 'history';
import { Provider } from 'mobx-react';
import companyStore from "./stores/CompanyStore";
import companyCouponsStore from "./stores/CompanyCouponsStore";
import appStore from "./stores/AppStore";
import { User } from "firebase";
import { auth } from "./config";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5C65DF',
    },
    secondary: {
      main: '#2d2d2d',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        color: 'black',
        backgroundColor: 'white'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 2,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      label: {
        textTransform: 'none',
        fontSize: 17,
        fontWeight: "normal",
        fontStyle: "normal",
        letterSpacing: -0.41,
        textAlign: "center",
      },
    },
  },
  typography: {
    fontFamily: 'Lato, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: 48,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: 1.2,
      color: '#fff',
    },
    h2: {
      fontSize: 26,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: 0,
      color: '#3c3c3c',
    },
    h3: {
      fontSize: 20,
      fontWeight: "normal",
      fontStyle: "normal",
      letterSpacing: 0.5,
      color: '#5f5f5f',
    },
    h4: {
      fontSize: 20,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: 0.5,
      color: '#2d2d2d'
    },
    h5: {
      fontSize: 22,
      fontStyle: "normal",
      letterSpacing: 0.55,
      color: '#fff',
    },
    body1: {
      fontSize: 15,
      fontStyle: "normal",
      letterSpacing: 0.38,
      color: "#545454"
    },
    body2: {
      fontSize: 17,
      fontWeight: "bold",
      fontStyle: "normal",
      letterSpacing: 0.43,
      color: "#545454"
    },
    caption: {
      fontSize: 13,
      fontStyle: "normal",
      letterSpacing: 0.32,
      color: "#545454",
      lineHeight: 1.4
    },
    subtitle1: {
      fontSize: 24,
      fontStyle: "normal",
      letterSpacing: 0.5,
      color: '#2d2d2d'
    }
  }
});
theme = responsiveFontSizes(theme);

const browserHistory = history.createBrowserHistory();
const stripePromise = loadStripe('pk_live_q0DC4XGA2k1GvpYnB5bEfIiu');

auth.onAuthStateChanged((user) => {
  if (user) {
    appStore.isAuthenticated = true;
    appStore.user = {
      displayName: user.displayName,
      email: user.email,
      metadata: user.metadata,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      uid: user.uid
    } as User;
  } else {
    appStore.signOut();
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider
        companyStore={companyStore}
        companyCouponsStore={companyCouponsStore}
        appStore={appStore}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Elements stripe={stripePromise}>
              <CssBaseline />
              <App />
            </Elements>
          </Router>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
