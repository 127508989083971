import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import queryString from 'query-string';
import { createStyles, Theme, WithStyles } from "@material-ui/core";
import { auth, db } from '../config'
import { inject, observer } from "mobx-react";
import { AppStore } from "../stores/AppStore";
import { CompanyStore } from "../stores/CompanyStore";
import { Company } from "../models/Company";
import { HOME, PAYMENT_REGISTRATION_START } from "../constants/Routes";

const styles = (theme: Theme) => createStyles({
    "@global": {
        body: {
            backgroundColor: "#fff"
        }
    },
    paper: {
        marginTop: 100,
        display: "flex",
        padding: 20,
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#e8ddff"
    },
    form: {
        marginTop: 1
    },
    errorText: {
        color: "#f50057",
        marginBottom: 5,
        textAlign: "center"
    }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    appStore: AppStore;
    companyStore: CompanyStore;
}

interface State {
    company: Company;
    email: string;
    password: string;
    tmp_token: string;
    confirmPassword: string;
    regtoken: string;
    errorMessage: string;
}

@inject('appStore', 'companyStore')
@observer
class SignUp extends Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = { email: "", password: "", confirmPassword: "", errorMessage: "", tmp_token: "", regtoken: "", company: null };
    }

    async componentDidMount() {
        const { location: { search } } = this.props;
        const queryParams = queryString.parse(search);
        if (!!!queryParams.id &&
            !!!queryParams.signup &&
            !!!queryParams.regtoken &&
            !!!queryParams.email) {
            this.props.history.push(HOME);
        }

        const company = await this.props.companyStore.getCompany(queryParams.id as string);
        // additional check if the registration is right with the link
        if (company.metadata['registration_token'] !== queryParams.regtoken) {
            console.log('the token is incorrect go home');
            this.props.history.push(HOME);
        };

        this.setState({
            company,
            regtoken: queryParams.regtoken as string,
            email: queryParams.email as string,
        })
    }

    handleEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    handlePasswordChange = ({ target }) => {
        this.setState({ password: target.value });
    };

    handleConfirmPasswordChange = ({ target }) => {
        this.setState({ confirmPassword: target.value });
    };

    handleSignUp = async () => {
        const { email, password, confirmPassword } = this.state;
        const company = this.state.company;
        this.setState({ errorMessage: "" });
        if (password !== confirmPassword) {
            this.setState({ errorMessage: "Password do not match" });
            return;
        }
        if (!!!company) {
            this.setState({ errorMessage: "Something went wrong" });
            return;
        }

        try {
            const user = await auth.createUserWithEmailAndPassword(email, password);
            try {
                await db.collection("approved-company").doc(company.id).update({ ownerId: user.user.uid });
                this.props.history.push(PAYMENT_REGISTRATION_START, {companyId: company.id});
            } catch (e) {
                this.setState({ errorMessage: "Something went wrong" });
            }
        } catch (error) {
            this.setState({ errorMessage: error.message })
        }
    }

    render() {
        const { classes } = this.props;
        const { errorMessage } = this.state;

        return (
            <Container component="main" maxWidth="xs" style={{paddingBottom: 60}}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign Up
                        </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        value={this.state.email}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={this.handleEmailChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={this.handlePasswordChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="confirmPassword"
                        label="Confirm password"
                        type="password"
                        id="confirmPassword"
                        onChange={this.handleConfirmPasswordChange}
                    />
                    {errorMessage && (
                        <Typography component="p" className={classes.errorText}>
                            {errorMessage}
                        </Typography>
                    )}
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleSignUp}
                    >
                        Sign Up
                        </Button>
                </Paper>
            </Container>
        );
    }
}

export default withStyles(styles)(withRouter(SignUp));
