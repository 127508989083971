import { Company } from '../models/Company';
import { STRIPE_CONNECT } from './../constants/Routes';

/**
 * Builds up the redirect back url.
 * @path path part to be appended to the url.
 */
export const buildRedirectUrl = (path?: string) => {
    let redirectTo = window.location.protocol.concat("//").concat(window.location.hostname);
    if (window.location.port) {
        redirectTo = redirectTo.concat(":").concat(window.location.port);
    }
    let topath = ''
    if (path) {
        topath = path.startsWith('/') ? path : '/'.concat(path);
    }
    return redirectTo.concat(topath);
}

export const redirect2Stripe = (company: Company, token: string) => {
    if (!!!token) {
        throw new Error('Token not prepared');
    }
    const redirectTo = buildRedirectUrl(STRIPE_CONNECT);
    let redirectUrl = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectTo}` +
        `&stripe_user[business_type]=company&stripe_user[country]=US&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&state=${token}`;
    redirectUrl = (company.name) ? redirectUrl.concat(`&stripe_user[business_name]=${company.name}`) : redirectUrl;
    redirectUrl = (company.phoneNumber) ? redirectUrl.concat(`&stripe_user[phone_number]=${company.phoneNumber}`) : redirectUrl;
    redirectUrl = (company.website) ? redirectUrl.concat(`&stripe_user[url]=${company.website}`) : redirectUrl;
    redirectUrl = (company.email) ? redirectUrl.concat(`&stripe_user[email]=${company.email}`) : redirectUrl;
    window.location.href = redirectUrl;
}
