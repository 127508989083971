import React from "react";
import { withStyles, WithStyles, Container, Theme, createStyles, Box, Typography, Hidden, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BackgroundImage from '../../assets/find-business@3x.jpg'
import { Company } from "../../models/Company";
import { HOME } from "../../constants/Routes";
import { inject } from "mobx-react";
import { CompanyStore } from "../../stores/CompanyStore";
import { redirect2Stripe } from "../../stores/Utils";

const styles = (theme: Theme) => createStyles({
    container: {
        backgroundImage:`url(${BackgroundImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        [theme.breakpoints.down('sm')]: {
            minHeight: 500,
            backgroundPosition: 'bottom',
            backgroundColor: '#5C65DF',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 700,
            backgroundPosition: 'center',
            backgroundColor: 'white',
        }
    },
    searchSuggestions: {
        border: "1px solid #D6D6D6",
        borderRadius: "2px",
    },
    divider: {
        height: theme.spacing(2),
    },
    textFieldContainer: {
        flexGrow: 1,
        position: 'relative',
    },
    popper: {
        zIndex: 10
    }
});

interface RouteState {
    companyId: string
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}, {}, RouteState> {
    companyStore?: CompanyStore;
}

interface State {
    redirect: boolean,
    company: Company,
    tmp_token: string
}

@inject('companyStore')
class PaymentRegistrationStart extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            company: null,
            tmp_token: ""
        }
    }

    async componentDidMount() {
        const { location: { state } } = this.props;
        if (!!!state.companyId) {
            this.props.history.push(HOME);
            return;
        }
        const { tmp_token, company} = await this.props.companyStore.prepareForStripeConnect(state.companyId);
        this.setState({company, tmp_token});
    }

    goToStripe() {
        this.setState({redirect: true})
        redirect2Stripe(this.state.company, this.state.tmp_token);
    }

    render() {
        const { classes } = this.props;
        return (
            <Box>
                <Box className={classes.container}>
                    <Box style={{ backgroundColor: '#5C65DF' }}>
                        <Container style={{ textAlign: 'center' }}>
                            <Hidden smDown>
                                <div style={{ paddingTop: 60 }}></div>
                            </Hidden>
                            <Typography variant="h1" component="h1" style={{ paddingTop: 64, paddingBottom: 40 }}>Payment Details</Typography>
                            <Typography variant="h5" component="h5" style={{ paddingBottom: 40 }}>Let's set up your payment details. When you press continue you will be redirected to our payment gateway.</Typography>
                            <Button size="medium" color='secondary' variant='outlined'
                                disabled={this.state.redirect}
                                style={{backgroundColor: 'white', border: '0px', width: 180}}
                                onClick={() => this.goToStripe()}>Continue</Button>
                        </Container>
                    </Box>
                </Box>
                <Hidden mdUp>
                    <div style={{ paddingTop: 80, backgroundColor: 'white' }}></div>
                </Hidden>
            </Box>
        )
    }
}

export default withStyles(styles)(withRouter(PaymentRegistrationStart));