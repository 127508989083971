import {action, observable} from 'mobx';
import {User} from "firebase";
import {auth} from "../config";

export class AppStore {
    @observable public isAuthenticated = false;
    @observable public user: User | undefined;

    @action public signOut() {
        this.isAuthenticated = false;
        this.user = undefined;
        auth.signOut();
    }

    async hasAdminRights() {
        const result = await auth?.currentUser?.getIdTokenResult();
        return result?.claims?.isSiteAdmin === true;
    }
}

const appStore = new AppStore();
export default appStore;
