import React from "react";
import { withStyles, WithStyles, Container, Paper, Theme, createStyles, TextField, Grid, Button, Box, Typography, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { Company } from "../models/Company";
import { CompanyStore } from "../stores/CompanyStore";
import { BUSINESS_ADDED } from "../constants/Routes";

const styles = (theme: Theme) => createStyles({
    paper: {
        border: "1px solid #D6D6D6"
    },
    headline: {
        marginTop: 32,
        padding: 10,
    },
    headlineTextAccent: {
        color: '#5c65df'
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    button: {
        width: '100%'
    },
    popper: {
        zIndex: 10
    }
});

interface State {
    name?: string,
    address?: string,
    lat?: number,
    lng?: number,
    giftCardLink?: string,
    website?: string,
    email?: string,
    phonenumber?: string,
    photoUrl?: string,
    isOwner: boolean,
    placeId?: string
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}, {}, State> {
    companyStore?: CompanyStore;
}

@inject('companyStore')
@observer
class AddBusiness extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            address: '',
            giftCardLink: '',
            email: '',
            website: '',
            phonenumber: '',
            isOwner: false,
            photoUrl: '',
        }
    }

    componentDidMount() {
        this.setState(this.props.history.location.state);
    }

    isValidEmail = (email?: string): boolean => {
        //eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    isValidURL = (str?: string): boolean => {
        if (!!!str) return true;
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    saveSalon = async () => {
        const { companyStore } = this.props;
        const company: Company = {
            active: false,
            address: this.state.address || '',
            name: this.state.name || '',
            email: this.state.email || '',
            phoneNumber: this.state.phonenumber || '',
            website: this.state.website || '',
            location: { lat: this.state.lat, lng: this.state.lng },
            giftCardLink: this.state.giftCardLink || '',
            isOwner: this.state.isOwner,
            ownerNotified: false,
            placeId: this.state.placeId || ''
        }
        companyStore!.addCompany(company);
        this.props.history.push(BUSINESS_ADDED);
    }

    canSave = () => {
        if (!!!this.state.email || !this.isValidEmail(this.state.email)) {
            return false;
        }
        return true;
    }

    render() {
        const { classes } = this.props;
        return (
            <Container>
                <Box className={classes.headline}>
                    <Typography gutterBottom variant="h2" component="h2">
                        Help us add business locations to <Box component="span" className={classes.headlineTextAccent}>Los Angeles, CA</Box>
                    </Typography>
                </Box>
                <Paper style={{
                    minHeight: 200,
                    marginTop: 20,
                    padding: 20,
                }}>
                    <Typography gutterBottom variant="h3" component="h3" style={{ paddingBottom: 16 }}>
                        Add salon details
                    </Typography>
                    <Grid container justify='center' alignItems='center' spacing={5}>
                        <Grid item xs={12} md={6}>
                            <TextField id="name"
                                className={classes.button}
                                variant="outlined"
                                label="BUSINESS NAME"
                                disabled={true}
                                required={true}
                                value={this.state.name}
                                onChange={event => this.setState({ name: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="address"
                                className={classes.button}
                                variant="outlined"
                                label="ADDRESS"
                                disabled={true}
                                required={true}
                                value={this.state.address}
                                onChange={event => this.setState({ address: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="phoneNumber"
                                className={classes.button}
                                variant="outlined"
                                disabled={true}
                                label="PHONE NUMBER"
                                required={true}
                                value={this.state.phonenumber}
                                onChange={event => this.setState({ phonenumber: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="website"
                                className={classes.button}
                                disabled={true}
                                variant="outlined"
                                label="WEBSITE" value={this.state.website}
                                onChange={event => this.setState({ website: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="email"
                                className={classes.button}
                                variant="outlined"
                                label="YOUR EMAIL"
                                error={!this.isValidEmail(this.state.email)}
                                value={this.state.email}
                                onChange={event => this.setState({ email: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField id="website"
                                className={classes.button}
                                variant="outlined"
                                error={!this.isValidURL(this.state.giftCardLink)}
                                label="GIFT CARD LINK"
                                value={this.state.giftCardLink}
                                onChange={event => this.setState({ giftCardLink: event.target.value })} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.isOwner} onChange={event => this.setState({ isOwner: event.target.checked })} name="checkedA" />}
                                    label="Check if own this business and have read the terms & conditions"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Button size="large" color="primary" variant="contained"
                            disabled={!this.canSave()}
                            style={{ marginTop: 16, minWidth: 240 }} 
                            onClick={this.saveSalon}>
                        Save
                    </Button>
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(withRouter(AddBusiness));
