import React from "react";
import {
    Avatar,
    Button,
    CircularProgress,
    createMuiTheme,
    Link,
    ListItemAvatar,
    MuiThemeProvider,
    TableCell,
    TableRow,
    Typography,
    withStyles,
    WithStyles,
    TextField
} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import {db, functions} from "../config";
import {Company} from "../models/Company";
import {inject, observer} from "mobx-react";
import {CompanyStore} from "../stores/CompanyStore";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { AppStore } from "../stores/AppStore";
import { HOME } from "../constants/Routes";


const styles = {
    avatar: {
        width: 88,
        height: 88,
    }
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    companyStore: CompanyStore,
    appStore: AppStore
}

interface State {
    data: string[][];
    approvedData: string[][];
    isLoading: boolean;
    tab: number;
}

@inject('companyStore', 'appStore')
@observer
class AdminDashboard extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            approvedData: [],
            isLoading: false,
            tab: 0
        }
    }

    getMuiTheme = () => createMuiTheme({
        typography: {},
    });

    approveCompany = async (id: string) => {
        const companyDb = await db.collection('company').doc(id).get();
        const data = companyDb.data() as Company;
        if (!!data.website)
            data.active = true;

        await Promise.all([
            db.collection('approved-company').doc(id).set(data),
            db.collection('company').doc(id).update({processed: true})]);

        this.getData();
    }

    getData = async () => {
        const {companyStore} = this.props;
        this.setState({isLoading: true});
        const [companies, approvedCompanies] = await Promise.all([companyStore.getCompanies(), companyStore.getApprovedCompanies()]);

        let list = [];

        for (const company of companies) {
            // @ts-ignore
            list.push([company.id, company.name, company.address, company.email, company.ownerEmail, !!company.ownerNotified ? 'true' : 'false', company.website, company.photoUrl, company.active]);
        }

        let approvedList = [];
        for (const company of approvedCompanies) {
            // @ts-ignore
            approvedList.push([company.id, company.name, company.address, company.email, company.ownerEmail, !!company.ownerNotified ? 'true' : 'false', company.website, company.photoUrl, company.active]);
        }

        this.setState({
            isLoading: false,
            data: list,
            approvedData: approvedList
        });
    }

    componentDidMount = async () => {
        // simple check 
        if (!await this.props.appStore.hasAdminRights()) {
            this.props.history.push(HOME);
        }
        await this.getData();
    }

    activateCompany = (id: string) => {
        db.collection('approved-company').doc(id).update({"active": true});
    }

    deactivateCompany = (id: string) => {
        db.collection('approved-company').doc(id).update({"active": false});
    }

    updateCompanyStatus = async (isActive: boolean, companyId: string) => {
        if (isActive) {
            await this.deactivateCompany(companyId);
        } else {
            await this.activateCompany(companyId);
        }

        const updateData = this.state.approvedData.map(row => row[0] === companyId ? [row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], isActive ? 'false' : 'true'] : row);
        this.setState({approvedData: updateData})
    }

    sentInviteEmail = async (id: string) => {
        const sysInviteOwner = functions.httpsCallable('sys_invite_salon_owner');
        try {
            await sysInviteOwner({ id });
            const updateData = this.state.approvedData.map(row => row[0] === id ? [row[0], row[1], row[2], row[3], row[4], 'true', row[6], row[7], row[8]] : row);
            this.setState({approvedData: updateData})
        } catch (e) {
            console.log("Failed to sent invitation", e);
            alert("Failed to sent invitation");
        }

    }

    handleTabChange = (event, tab) => {
        this.setState({tab});
    };


    render() {
        const { companyStore } = this.props;
        const {isLoading, data, approvedData, tab} = this.state;
        const {classes} = this.props;
        const columns: MUIDataTableColumnDef[] = ["id", "name", "address", "email", {
            name: "Owner's email",
            options: {
                customBodyRender: (value, tableMetadata, updateValue) => {
                    return (
                        <Box width={250}>
                            <TextField id="owner email"
                                        variant="outlined"
                                        label="Owner's email"
                                        value={value}
                                        onChange={event => updateValue(event.target.value, null, null)}
                                        onBlur={() => companyStore.updateOwnersEmail(tableMetadata.rowData[0], value, tab === 1)}/>
                        </Box>
                    )
                }
            }
        }, "Owner notified", {
            name: 'website',
            options: {
                customBodyRender: (value, tableMetadata, updateValue) => {
                    return (
                        <Link href={value} target="_blank" rel="noopener">
                            {value}
                        </Link>
                    )
                }
            }
        }, {
            name: 'image',
            options: {
                customBodyRender: (value, tableMetadata, updateValue) => {
                    return (
                        <ListItemAvatar>
                            <Avatar
                                className={classes.avatar}
                                variant="rounded"
                                src={value}
                            />
                        </ListItemAvatar>
                    )
                }
            }
        }, {
            name: 'active',
            options: {
                display: "false"
            }
        }];
        const options: MUIDataTableOptions = {
            serverSide: false,
            filter: true,
            rowsPerPageOptions: [10, 20, 50, 100],
            responsive: 'stacked',
            filterType: 'textField',
            selectableRows: 'none',
            pagination: true,
            expandableRows: true,
            renderExpandableRow: (row, rowMeta) => {
                const companyId = row[0];
                const colSpan = row.length + 1;
                return (
                    <TableRow>
                        <TableCell colSpan={colSpan}>
                            <Button
                                style={{margin: 10}}
                                variant="contained" color="primary"
                                onClick={() => this.approveCompany(companyId)}>
                                Approve company
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            }
        }

        const approvedOptions: MUIDataTableOptions = {
            serverSide: false,
            filter: true,
            rowsPerPageOptions: [10, 20, 50, 100],
            responsive: 'stacked',
            filterType: 'textField',
            selectableRows: 'none',
            pagination: true,
            expandableRows: true,
            renderExpandableRow: (row, rowMeta) => {
                const companyId = row[0];
                const isActive = JSON.parse(row[8]);
                const colSpan = row.length + 1;

                return (
                    <TableRow>
                        <TableCell colSpan={colSpan}>
                            <Button

                                style={{margin: 10}}
                                variant="contained" color="primary"
                                onClick={() => this.updateCompanyStatus(isActive, companyId)}>
                                {isActive ? 'Deactivate' : 'Activate'}
                            </Button>

                            <Button
                                style={{margin: 10}}
                                variant="contained" color="primary"
                                onClick={() => this.sentInviteEmail(companyId)}>
                                Invite Owner
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            }
        }


        function TabPanel(props) {
            const {children, value, index, ...other} = props;

            return (
                <Typography
                    component="div"
                    role="tabpanel"
                    hidden={value !== index}
                    id={`simple-tabpanel-${index}`}
                    aria-labelledby={`simple-tab-${index}`}
                    {...other}
                >
                    {value === index && <Box p={3}>{children}</Box>}
                </Typography>
            );
        }

        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
                <AppBar position="static">
                    <Tabs value={tab} onChange={this.handleTabChange} aria-label="simple tabs example">
                        <Tab label="Companies"/>
                        <Tab label="Approved companies"/>
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                    <MUIDataTable
                        title={<Typography variant="h6">
                            Companies
                            {isLoading &&
                            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}}/>}
                        </Typography>
                        }
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <MUIDataTable
                        title={<Typography variant="h6">
                            Approved Companies
                            {isLoading &&
                            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}}/>}
                        </Typography>
                        }
                        data={approvedData}
                        columns={columns}
                        options={approvedOptions}
                    />
                </TabPanel>

            </MuiThemeProvider>
        );
    }
}

export default withStyles(styles)(withRouter(AdminDashboard));
