import React from "react";
import { withStyles, WithStyles, Container, Theme, createStyles, Box, Typography, Hidden } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BackgroundImage from '../assets/find-business@3x.jpg'

const styles = (theme: Theme) => createStyles({
    container: {
        backgroundImage:`url(${BackgroundImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        [theme.breakpoints.down('sm')]: {
            minHeight: 500,
            backgroundPosition: 'bottom',
            backgroundColor: '#5C65DF',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 700,
            backgroundPosition: 'center',
            backgroundColor: 'white',
        }
    },
    searchSuggestions: {
        border: "1px solid #D6D6D6",
        borderRadius: "2px",
    },
    divider: {
        height: theme.spacing(2),
    },
    textFieldContainer: {
        flexGrow: 1,
        position: 'relative',
    },
    popper: {
        zIndex: 10
    }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
    address: string,
    placeId: string
}

class AddBusinessDone extends React.Component<Props, State> {

    render() {
        const { classes } = this.props;
        return (
            <Box>
                <Box className={classes.container}>
                    <Box style={{ backgroundColor: '#5C65DF' }}>
                        <Container style={{ textAlign: 'center' }}>
                            <Hidden smDown>
                                <div style={{ paddingTop: 60 }}></div>
                            </Hidden>
                            <Typography variant="h5" component="h5" style={{ paddingTop: 64 }}>THANK YOU</Typography>
                            <Typography variant="h1" component="h1" style={{ paddingTop: 24, paddingBottom: 40 }}>Your local business has been added</Typography>
                            <Typography variant="h5" component="h5" >Our team will verify the business as soon as possible and notify you when it is accepted</Typography>
                        </Container>
                    </Box>
                </Box>
                <Hidden mdUp>
                    <div style={{ paddingTop: 80, backgroundColor: 'white' }}></div>
                </Hidden>
            </Box>
        )
    }
}

export default withStyles(styles)(withRouter(AddBusinessDone));