import React from "react";
import { withStyles, WithStyles, Container, Typography, Grid, Paper, TextField, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ContactUs } from "../models/Email";
import { functions } from '../config';

const styles = {
    textField: {
        width: '100%',
        marginTop: 40
    },
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
    name?: string,
    email?: string,
    subject?: string,
    message?: string,
    email_sent: boolean
}

class Contact extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            email_sent: false
        }
    }

    isValidEmail = (email?: string): boolean => {
        if (!!!email) {
            return true;
        }
        //eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendMessage = async () => {
        const contactUs = functions.httpsCallable('contact_us');
        const data: ContactUs = {
            email: this.state.email,
            message: this.state.message,
            subject: this.state.subject,
            name: this.state.name
        };
        try {
            this.setState({email_sent: true});
            await contactUs(data);
        } catch (err) {
            // should be sent!
            // if not show error ?
        }
    }

    canSend = () => {
        return this.isValidEmail(this.state.email) && this.state.message?.length > 5;
    }

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth={"md"} style={{paddingLeft: 16, paddingRight: 16, marginBottom: 80,}} disableGutters>
                {(this.state.email_sent) && (
                    <Grid item style={{paddingTop: 73}}>
                        <Typography variant="h2" component="h2">Thank you for contacting us!</Typography>
                    </Grid>
                )}
                {(!this.state.email_sent) && (
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} style={{paddingTop: 73}}>
                            <Typography variant="h2" component="h2">Contact us</Typography>
                            <Typography variant="h4" component="p" style={{fontWeight: "normal", lineHeight: 2, marginTop: 20, letterSpacing: 0.8}}>If you have any questions or feedback about the service dont be afarid to reach out</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{paddingTop: 43}}>
                            <Paper style={{padding: 24}}>
                                <TextField id="name"
                                    className={classes.textField}
                                    variant="outlined"
                                    label="YOUR NAME"
                                    value={this.state.name}
                                    onChange={event => this.setState({ name: event.target.value })} />
                                <TextField id="email"
                                    className={classes.textField}
                                    variant="outlined"
                                    label="YOUR EMAIL"
                                    error={!this.isValidEmail(this.state.email)}
                                    value={this.state.email}
                                    onChange={event => this.setState({ email: event.target.value })} />
                                <TextField id="subject"
                                    className={classes.textField}
                                    variant="outlined"
                                    label="SUBJECT"
                                    value={this.state.subject}
                                    onChange={event => this.setState({ subject: event.target.value })} />
                                <TextField id="message"
                                    className={classes.textField}
                                    variant="outlined"
                                    label="YOUR MESSAGE"
                                    value={this.state.message}
                                    multiline
                                    rows="8"
                                    onChange={event => this.setState({ message: event.target.value })} />
                                <Button size="large" color="primary" 
                                        disabled={!this.canSend()}
                                        variant="contained" style={{ marginTop: 24, width: '100%' }}
                                        onClick={this.sendMessage}>
                                    Send
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Container>
        )
    }
}

export default withStyles(styles)(withRouter(Contact));
