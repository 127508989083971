
export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';

export const HOME = '/';
export const SALONS = '/salons';
export const SALON = '/salons/:id';
export const SALON_CHECKOUT = '/salons/:id/checkout';
export const FAQS = '/faqs';
export const CONTACT = '/contact';
export const FIND_BUSINESS = '/find-business';
export const ADD_BUSINESS = '/add-business';
export const BUSINESS_ADDED = '/business-added';
export const PAYMENT_REGISTRATION_START = '/payment-registration';
export const PAYMENT_REGISTRATION_END = '/payment-reg-completed';
export const PAYMENT_SUCCESSFULL = '/purchase-successfull';
export const STRIPE_CONNECT = '/stripe-connect';
export const COMPANY_DASHBOARD = '/company-dashboard';
export const ADMIN_DASHBOARD = '/admin-dashboard';
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/privacy-policy#cookies';
