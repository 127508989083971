import { db } from "../config";
import { Company } from "../models/Company";
import { observable } from "mobx";

const companyPath = 'company';
const approvedCompanyPath = 'approved-company';

export class CompanyStore {

    @observable public company: Company | undefined;

    async getCompanies(): Promise<Array<Company>> {
        const companies = await db.collection(companyPath)
            .orderBy('name')
            .get();

        return companies.docs
            .filter(c => c.data().processed !== true)
            .map(d => ({...d.data(), id: d.id} as Company));
    }

    async getApprovedCompanies(active?: boolean): Promise<Array<Company>> {
        let query = db.collection(approvedCompanyPath)
            .orderBy('name');
        if(active !== undefined) {
            query = query.where('active', '==', active)
        }

        const companies = await query.get();
        return companies.docs.map(d => ({...d.data(), id: d.id} as Company));
    }

    async getCompany(id: string): Promise<Company> {
        let query = db.collection(approvedCompanyPath)
            .doc(id);
        const company = await query.get();
        if (company.exists) {
            return {...company.data(), id: company.id} as Company;
        }
        throw Error('Not Found');
    }

    async updateOwnersEmail(id: string, ownerEmail: string, approved: boolean) {
        await db.collection(approved ? approvedCompanyPath : companyPath).doc(id).update({ ownerEmail })
    }

    async getCompanyByUser(id: string): Promise<Company> {
        const companies = await db.collection(approvedCompanyPath).where('ownerId', '==', id).get();
        if (companies.size === 1) {
            return {...companies.docs[0].data(), id: companies.docs[0].id} as Company;
        }
        return null;
    }

    async addCompany(company: Company) {
        if (company.isOwner) {
            company.ownerEmail = company.email;
        }
        const companyRef = db.collection(companyPath).doc();
        await companyRef.set(company);
    }

    async attachUser(companyId: string, ownerId: string) {
        db.collection(companyPath).doc(companyId).update({ ownerId });
    }

    prepareForStripeConnect = async (companyId: string) => {
        const tmp_token = Math.random().toString(36).slice(2);
        const update = {};
        update['metadata.stripe.tmp_token'] = tmp_token;
        const res = await Promise.all([this.getCompany(companyId), db.collection(approvedCompanyPath).doc(companyId).update(update)]);
        return { tmp_token, company: res[0] };
    }
}

const companyStore = new CompanyStore();
export default companyStore;
