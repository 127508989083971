import { Button, Container, createStyles, Grid, TextField, Theme, WithStyles, withStyles, Box, Typography, Link, InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { functions } from '../../config';
import { Company } from '../../models/Company';
import { PaymentRequest } from '../../models/Payment';
import { CompanyStore } from "../../stores/CompanyStore";
import './stripe.css';
import { PAYMENT_SUCCESSFULL } from '../../constants/Routes';

const styles = (theme: Theme) => createStyles({
    priceButton: {
        minWidth: 117,
        [theme.breakpoints.down('xs')]: {
            minWidth: 93,
        },
    },
    headline: {
        marginTop: 32
    },
    errorText: {
        color: "#f50057",
        marginBottom: 5,
        textAlign: "center"
    }
});

interface CheckoutProps extends WithStyles<typeof styles>, RouteComponentProps {
    elements: StripeElements;
    stripe: Stripe;
    companyId: string;
    companyStore: CompanyStore;
}

interface CheckoutState {
    company: Company;
    recipientName: string;
    recipientEmail: string;
    email: string;
    name: string;
    paymentType: string;
    customPaymentAmount: string;
    paymentInProgress: boolean;
    errorMessage: string;
    invalidCvc: boolean;
}

@inject('companyStore')
@observer
export class CheckoutForm extends React.Component<CheckoutProps, CheckoutState> {

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            recipientEmail: '',
            recipientName: '',
            email: '',
            name: '',
            customPaymentAmount: '50',
            paymentType: '$50',
            paymentInProgress: false,
            errorMessage: null,
            invalidCvc: false
        };
    }

    async componentDidMount() {
        const { companyStore, companyId } = this.props;
        const company = await companyStore.getCompany(companyId)
        this.setState({ company })
    }

    invalidCustom = () => {
        return this.state.paymentType === 'Custom' && isNaN(parseFloat(this.state.customPaymentAmount));
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            paymentInProgress: true
        });
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }
        let amount = 2500;
        switch (this.state.paymentType) {
            case '$25':
                amount = 2500;
                break;
            case '$50':
                amount = 5000;
                break;
            case '$70':
                amount = 7000;
                break;
            case '$100':
                amount = 10000;
                break;
            case 'Custom':
                amount = parseFloat(this.state.customPaymentAmount) * 100
                break;
        }

        const buyGiftCardIntend = functions.httpsCallable('buy_gift_card');
        const data: PaymentRequest = {
            amount,
            companyId: this.state.company.id,
            stripeId: this.state.company.stripePayments.stripe_id,
            name: this.state.name,
            email: this.state.email,
            recEmail: this.state.recipientEmail,
            recName: this.state.recipientName
        }
        try {
            const response = await buyGiftCardIntend(data);
            const intendSecret = response.data.secret;
            const cardNumberElement = elements.getElement(CardNumberElement);
            // eslint-disable-next-line
            const { paymentIntent, error } = await stripe.confirmCardPayment(intendSecret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        name: this.state.name
                    }
                }
            });
            if (!!error) {
                if (error.code === "incorrect_cvc") {
                    this.setState({
                        invalidCvc: true
                    })
                }
                this.setState({
                    paymentInProgress: false,
                    errorMessage: error.message
                });
            } else {
                this.props.history.push(PAYMENT_SUCCESSFULL);
            }
        } catch (err) {
            console.log('Failed to buy GiftCard', err);
            throw new Error('Failed to buy GiftCard');
        }
    };

    handlePrice = (event: React.MouseEvent<HTMLElement>, paymentType: string | null) => {
        if (paymentType !== null) {
            this.setState({
                paymentType
            });
        }
    };

    disablePay = () => {
        // eslint-disable-next-line
        const { stripe, elements } = this.props;
        if (!stripe) return false;
        if (this.state.paymentInProgress) return false;
        if (this.invalidCustom()) return false;
        return true;
    }

    clearErrors = () => {
        this.setState({
            errorMessage: null,
            invalidCvc: false
        })
    }

    render() {
        const { stripe, classes } = this.props;
        const { errorMessage } = this.state;
        return (
            <Container maxWidth={"sm"} disableGutters style={{padding: 10, marginBottom: 40}}>
                <Box className={classes.headline} alignItems='center' justifyContent='center'>
                    <Typography gutterBottom variant="h2" component="h2">
                        Gift card amount
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        We will match up to $50 for treatment spending credit on the <Link href="http://itunes.apple.com/us/app/glammly/id1449886756?mt=8">Glammly app</Link> with a verified salon.
                    </Typography>
                </Box>

                <FormControl onSubmit={this.handleSubmit} fullWidth style={{marginTop: 20}}>
                    <Grid container justify='center' alignItems='center' alignContent='center' spacing={3}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <ToggleButtonGroup color="primary"
                                exclusive
                                value={this.state.paymentType}
                                aria-label="outlined primary button group"
                                onChange={this.handlePrice}>
                                <ToggleButton value="$25" aria-label="left aligned" className={classes.priceButton}>$25</ToggleButton>
                                <ToggleButton value="$50" aria-label="left aligned" className={classes.priceButton}>$50</ToggleButton>
                                <ToggleButton value="$70" aria-label="left aligned" className={classes.priceButton}>$70</ToggleButton>
                                <ToggleButton value="$100" aria-label="left aligned" className={classes.priceButton}>$100</ToggleButton>
                                <ToggleButton value="Custom" aria-label="left aligned" className={classes.priceButton}>Custom</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {(this.state.paymentType === 'Custom') && (
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    error={this.invalidCustom()}
                                    value={this.state.customPaymentAmount}
                                    name="custom"
                                    label="Enter custom gift card amount"
                                    type='number'
                                    id="custom"
                                    onChange={e => this.setState({ customPaymentAmount: e.target.value })}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                name="name"
                                label="Recipient Name"
                                type="text"
                                value={this.state.recipientName}
                                id="name"
                                onChange={e => this.setState({ recipientName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                value={this.state.recipientEmail}
                                name="email"
                                label="Email address"
                                type='email'
                                id="email"
                                onChange={e => this.setState({ recipientEmail: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                name="name"
                                label="Your name"
                                type="text"
                                value={this.state.name}
                                id="name"
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="none"
                                fullWidth
                                value={this.state.email}
                                name="email"
                                label="Your email"
                                type='email'
                                id="email"
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CardNumberElement options={{ showIcon: true }} />
                        </Grid>
                        <Grid item xs={6}>
                            <CardExpiryElement />
                        </Grid>
                        <Grid item xs={6} className={this.state.invalidCvc ? 'invalid' : ''}>
                            <CardCvcElement onChange={() => this.setState({invalidCvc: false})}/>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Typography component="p" className={classes.errorText}>
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                        <Button type="submit"
                            disabled={!stripe || this.state.paymentInProgress}
                                color='primary'
                            variant='contained'
                            style={{ minWidth: 250 }}
                            onClick={this.handleSubmit}>Pay</Button>
                    </Grid>
                </FormControl>
            </Container>
        );
    }
}

export default withStyles(styles)(withRouter(CheckoutForm))