import React from "react";
import { withStyles, WithStyles, Container, CardContent, Box, Typography, Card, CardMedia, Button, Grid, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { CompanyStore } from "../stores/CompanyStore";
import { Company } from "../models/Company";
import { HOME, SALON_CHECKOUT } from "../constants/Routes";

const styles = theme => ({
    headline: {
        marginTop: 32,
        padding: 10,
    },
    headlineTextAccent: {
        color: '#5c65df'
    },
    card: {
        marginBottom: 80,
        display: 'flex',
    },
    content: {
        padding: 24,
    },
    media: {
        [theme.breakpoints.down('sm')]: {
            minHeight: 240
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 700
        }
    }
})

interface RouterState {
    photoUrl?: string
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{}, {}, RouterState> {
    companyId: any
    companyStore?: CompanyStore
}

interface State {
    company?: Company,
    photoUrl?: string
}

@inject('companyStore')
@observer
class Salon extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
        this.state = {
            company: null,
            photoUrl: ''
        };
    }

    private mapRef;
    private placesService: google.maps.places.PlacesService | null;

    async componentDidMount() {
        const { companyStore, companyId, location } = this.props;
        const photoUrl = location?.state?.photoUrl;
        try {
            const company = await companyStore!.getCompany(companyId)
            this.setState({ company })
            if (!!photoUrl) {
                this.setState({
                    photoUrl
                })
            } else {
                // if incoming via link
                this.placesService = new google.maps.places.PlacesService(this.mapRef.current);
                var request = {
                    placeId: company.placeId,
                    fields: ['photos']
                };

                this.placesService.getDetails(request, (result, status) => {
                    const photoUrl = result?.photos && result?.photos[0]?.getUrl({ maxHeight: 700, maxWidth: 1200 });
                    this.setState({
                        photoUrl
                    });
                });
            }
        } catch (e) {
            this.props.history.push(HOME);
        }
    }

    buyGiftCard = () => {
        console.log(this.state.company)
        if (!!this.state?.company?.giftCardLink) {
            this.buyGiftCardViaGiftCardLink();
        } else if (!!this.state.company?.stripePayments?.stripe_id) {
            this.buyViaStripe();
        } else {
            // TODO show error message.
        }
    }

    buyGiftCardViaGiftCardLink() {
        window.location.replace(this.state.company?.giftCardLink as string);
    }

    buyViaStripe() {
        this.props.history.push(SALON_CHECKOUT.replace(':id', this.state.company.id));
    }

    render() {
        const { classes } = this.props;
        const { company } = this.state;

        return (
            <Container maxWidth={"lg"} style={{ paddingLeft: 16, paddingRight: 16 }} disableGutters>
                <div ref={this.mapRef}></div>
                <Box className={classes.headline}>
                    <Typography gutterBottom variant="h2" component="h2">
                        Thank you for helping support local business <Box component="span" className={classes.headlineTextAccent}>local business</Box>
                    </Typography>
                </Box>
                {company &&
                    <Card className={classes.card}>
                        <Grid container justify='center' alignItems='center' spacing={5}>
                            <Grid item xs={12} md={6}>
                                <CardMedia
                                    className={classes.media}
                                    image={this.state.photoUrl}
                                    title={company.name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CardContent className={classes.content}>
                                    <Typography gutterBottom variant="h2" component="h2" style={{ marginBottom: 16 }}>
                                        {company.name}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="h4" style={{ marginBottom: 10 }}>
                                        {company.address}
                                    </Typography>
                                    {company.stripePayments?.stripe_id &&
                                        <Typography variant="body1" component="p">
                                            This business is eligible for a Glammly credit. Glammly will match the value of the gift card you purchase up to $50. You will receive an email with a direct link to the Glammly app to redeem your credit.
                                        </Typography>
                                    }
                                    {!company.stripePayments?.stripe_id &&
                                        <Typography variant="body1" component="p">
                                            This business has not been claimed yet. You can still purchase a giftcard to this salon on their website, but will not be eligible for a credit on your Glammly account.
                                            <p>Is this your business? Claim it at  <Link href="mailto:info@saveyoursalons.com">info@saveyoursalons.com</Link></p>
                                        </Typography>
                                    }
                                    <Typography gutterBottom variant="h4" component="h4" style={{ marginTop: 16, marginBottom: 16, color: '#5c65df' }}>
                                        Step 1
                                    </Typography>
                                    {company.stripePayments?.stripe_id &&
                                        <Typography variant="body1" component="p">
                                            Buy a giftcard from this link for you {company.name}
                                        </Typography>
                                    }
                                    {!company.stripePayments?.stripe_id &&
                                        <Typography variant="body2" component="p" style={{ fontWeight: "normal" }}>
                                            Buy a giftcard from this link for {company.name}
                                        </Typography>
                                    }
                                    <Grid container direction={'column'}>
                                        <Grid item xs={12} md={6}>
                                            <Button size="medium" color='primary' variant='contained' style={{ marginTop: 16, marginBottom: 16, width: '100%' }} onClick={this.buyGiftCard}>
                                                Buy a gift card
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {!company.stripePayments?.stripe_id &&
                                    <Typography variant="caption" component="p">
                                        Clicking this link redirects you to a third party website and you are leaving Save your Salons website website.
                                    </Typography>
                                    }
                                    <Typography gutterBottom variant="h4" component="h4" style={{ marginTop: 16, marginBottom: 16, color: '#5c65df' }}>
                                        Step 2
                                    </Typography>
                                    <Typography variant="body2" component="p" style={{ fontWeight: "normal" }}>
                                        Help spread the word for you local business
                                    </Typography>
                                    <Grid container direction={'column'}>
                                        <Grid item xs={12} md={6}>
                                            <Button size="medium" color='primary' variant='contained' style={{ marginTop: 16, width: '100%', backgroundColor: '#00abee' }}>
                                                Twitter
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button size="medium" color='primary' variant='contained' style={{ marginTop: 8, width: '100%', backgroundColor: '#3a5998' }}>
                                                Share on facebook
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button size="medium" color='primary' variant='contained' style={{ marginTop: 8, width: '100%', backgroundColor: '#d44538' }}>
                                                Share via Email
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                }
            </Container>
        )
    }
}

export default withStyles(styles)(withRouter(Salon));
