import React from "react";
import { WithStyles, withStyles, MuiThemeProvider, Typography, createMuiTheme, CircularProgress, Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { AppStore } from "../stores/AppStore";
import { CompanyStore } from "../stores/CompanyStore";
import { HOME, COMPANY_DASHBOARD } from "../constants/Routes";
import { Company, DasbordLinkRequest, DashboardLinkResponse } from "../models/Company";
import { functions } from "../config";
import { buildRedirectUrl } from "../stores/Utils";
import { CompanyCouponsStore } from "../stores/CompanyCouponsStore";
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumnDef } from "mui-datatables";

const styles = {}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    appStore: AppStore;
    companyStore: CompanyStore;
    companyCouponsStore: CompanyCouponsStore;
}

interface State {
    company: Company;
    url: string;
    isLoading: boolean;
    data: string[][];
}

@inject('appStore', 'companyStore', 'companyCouponsStore')
@observer
class CompanyDashboard extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            url: null,
            isLoading: true,
            data: []
        }
    }

    async componentDidMount() {
        const company = await this.props.companyStore.getCompanyByUser(this.props.appStore.user.uid);
        if (company === null) { this.props.history.push(HOME); return; };

        await this.props.companyCouponsStore.getCoupons(company.id);
        const data = this.props.companyCouponsStore.coupons.map(c => [c.couponId, `$${c.amount/100}`, new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          })
                .format(new Date(c.creationTime)), c.name, c.email])
        this.setState({
            company,
            isLoading: false,
            data
        })
        const redirectUrl = buildRedirectUrl(COMPANY_DASHBOARD);
        const stripeDashboarRequest: DasbordLinkRequest = {
            companyId: company.id,
            redirectUrl
        }
        const stripeDashboard = functions.httpsCallable('sys_stripe_dashboard');
        const result = await stripeDashboard(stripeDashboarRequest)
        const resData = result.data as DashboardLinkResponse;
        this.setState({ url: resData.url });
    }

    handleStripe = () => {
        window.open(this.state.url);
    }

    getMuiTheme = () => createMuiTheme({
        typography: {},
    });

    render() {
        const { isLoading } = this.state;
        const columns: MUIDataTableColumnDef[] = ["Coupon ID", "Amount", "Purchase Date", "Buyer Name", "Buyer Email"];
        const options: MUIDataTableOptions = {
            serverSide: false,
            filter: true,
            rowsPerPageOptions: [10, 20, 50, 100],
            filterType: 'textField',
            selectableRows: 'none'
        }

        return (
            <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                    title={<div>
                        {isLoading &&
                            <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                        {!isLoading && 
                        <Typography variant="h6">
                            Purchased Gift Cards
                            <Button color='secondary' variant='contained' 
                                        disabled={!!!this.state.url}
                                        onClick={this.handleStripe}>Payments Dashboard</Button>
                        </Typography>
                        }
                    </div>
                    }
                    data={this.state.data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        );
    }
}
export default withStyles(styles)(withRouter(CompanyDashboard));
