import React from "react";
import PlacesAutocomplete from 'react-places-autocomplete';
import { withStyles, WithStyles, Container, TextField, Paper, MenuItem, Popper, Theme, createStyles, Box, Typography, Hidden } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ADD_BUSINESS } from "../constants/Routes";
import BackgroundImage from '../assets/find-business@3x.jpg'

const styles = (theme: Theme) => createStyles({
    container: {
        backgroundImage:`url(${BackgroundImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        [theme.breakpoints.down('sm')]: {
            minHeight: 500,
            backgroundPosition: 'bottom',
            backgroundColor: '#5C65DF',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 700,
            backgroundPosition: 'center',
            backgroundColor: 'white',
        }
    },
    searchSuggestions: {
        border: "1px solid #D6D6D6",
        borderRadius: "2px",
    },
    divider: {
        height: theme.spacing(2),
    },
    textFieldContainer: {
        flexGrow: 1,
        position: 'relative',
    },
    popper: {
        zIndex: 10
    }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
    address: string,
    placeId: string
}

class FindBusiness extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            placeId: '',
        };
        this.popperRef = React.createRef();
        this.mapRef = React.createRef();
        this.placesService = null;
    }

    private popperRef;
    private inputRef;
    private mapRef;

    private placesService: google.maps.places.PlacesService | null;

    handleChange = address => {
        this.setState({ address });
    };

    componentDidMount() {
        this.placesService = new google.maps.places.PlacesService(this.mapRef.current);
    }

    handleSelect = async (address, placeId) => {
        if (placeId) {
            this.setState({ address: address, placeId: placeId });
            try {
                var request = {
                    placeId,
                    fields: ['name', 'rating', 'formatted_phone_number', 'formatted_address', 'geometry', 'website']
                };
                if (!!this.placesService) {
                    this.placesService.getDetails(request, (result, status) => {
                        const result_copy = {
                            name: result?.name,
                            address: result?.formatted_address,
                            lat: result?.geometry?.location?.lat(),
                            lng: result?.geometry?.location?.lng(),
                            website: result?.website,
                            phonenumber: result.formatted_phone_number,
                            placeId
                        }
                        this.props.history.push(ADD_BUSINESS, result_copy);
                    });
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    renderSuggestion({ suggestion, index, itemProps }) {
        return (
            <MenuItem
                {...itemProps}
                key={suggestion.description}
                divider={true}
                component="div"
                style={{
                    fontWeight: 400,
                    fontSize: 18,
                    padding: 16,
                    whiteSpace: 'normal'
                }}
            >
                {suggestion.description}
            </MenuItem>
        );
    }

    render() {
        const { classes } = this.props;
        const bounds = {
            west: -118.709072,
            north: 34.370536,
            south: 33.622647,
            east: -117.816956
        };
        return (
            <Box>
                <Box className={classes.container}>
                    <div ref={this.mapRef}></div>
                    <Box style={{ backgroundColor: '#5C65DF', height: '100%' }}>
                        <Container style={{ textAlign: 'center' }}>
                            <Hidden smDown>
                                <div style={{ paddingTop: 60 }}></div>
                            </Hidden>
                            <Typography variant="h5" component="h5" style={{ paddingTop: 64 }}>ADD YOUR LOCAL BUSINESS</Typography>
                            <Typography variant="h1" component="h1" style={{ paddingTop: 24, paddingBottom: 40 }}>Help us add your local spas and&nbsp;salons</Typography>
                            <PlacesAutocomplete
                                searchOptions={{
                                    bounds,
                                    types: ['establishment']
                                }}
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}>
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (

                                    <div className={classes.textFieldContainer}>
                                        <TextField
                                            {...getInputProps({
                                                className: 'location-search-input',
                                                fullWidth: true,
                                                margin: 'normal',
                                            })}
                                            placeholder={"Start typing the business name "}
                                            inputRef={node => this.inputRef = node}
                                            size={"medium"}
                                            style={{ backgroundColor: '#fff', borderRadius: 2, padding: 16 }} />
                                        <div ref={this.popperRef}>
                                            <Popper open={suggestions.length > 0}
                                                anchorEl={this.popperRef.current}
                                                className={classes.popper}
                                                disablePortal={true}
                                                placement="bottom">
                                                <Paper className={classes.searchSuggestions} style={{ width: this.inputRef ? this.inputRef.clientWidth : null }} square>
                                                    {suggestions.map((suggestion, index) =>
                                                        this.renderSuggestion({
                                                            suggestion,
                                                            index,
                                                            itemProps: getSuggestionItemProps(suggestion)
                                                        })
                                                    )}
                                                </Paper>

                                            </Popper>
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Container>
                    </Box>
                </Box>
                <Hidden mdUp>
                    <div style={{ paddingTop: 80, backgroundColor: 'white' }}></div>
                </Hidden>
            </Box>
        )
    }
}

export default withStyles(styles)(withRouter(FindBusiness));