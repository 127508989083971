import React from 'react';
import { withStyles, WithStyles, Box, Container, Grid, Typography, Button, Link} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PRIVACY_POLICY, COOKIE_POLICY } from '../constants/Routes';

const styles = theme => ({
    root: {
        backgroundColor: '#f5f5f5', 
        width: `100%`
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0, 
            paddingBottom: 0   
        },
        [theme.breakpoints.up('md')]: { 
            paddingTop: 50, 
            paddingBottom: 50
        }
    },
    copy: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right'
        }
    }
})

interface FooterProps extends WithStyles<typeof styles>, RouteComponentProps {
}

interface FooterState {
}

class Footer extends React.Component<FooterProps, FooterState> {

    render() {
        const { classes } = this.props;
        return (
            <Box className={classes.root}>
                <Container maxWidth={"lg"} >
                    <Grid container spacing={0} style={{paddingTop: 20, paddingBottom: 20}}>
                        <Grid item xs={12} md={3} style={{paddingTop: 20, paddingBottom: 20}}>
                            <span style={{
                                color: '#2d2d2d',
                                fontFamily: 'PlayfairDisplay',
                                fontSize: 22,
                                fontWeight: "bold",
                                fontStyle: "normal",
                                letterSpacing: -0.53
                            }}>Save your salons</span>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.button}>
                            <Link href={PRIVACY_POLICY} color="inherit" style={{textDecoration: 'none'}}>
                                <Button size="medium" color="secondary" variant="text">
                                    Privacy policy
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.button}>
                            <Link href={COOKIE_POLICY} color="inherit" style={{textDecoration: 'none'}}>
                                <Button size="medium" color="secondary" variant="text">
                                    Cookie policy
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item sm={12} md={2} className={classes.button}>
                            <Link href={PRIVACY_POLICY} color="inherit" style={{textDecoration: 'none'}}>
                                <Button size="medium" color="secondary" variant="text">
                                    Terms and conditons 
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={3} style={{paddingTop: 20, paddingBottom: 20}}>
                            <Typography variant="body1" component="p" className={classes.copy}>2020 rights reserved.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>    
        )
    }
}

export default withStyles(styles)(withRouter(Footer));
