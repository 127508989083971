import React from "react";
import { withStyles, WithStyles, Container, Box, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Paper, Link } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FIND_BUSINESS} from '../constants/Routes';

const styles = {
    headline: {
        marginTop: 32,
        padding: 10
    },
    question: {
        padding: 16
    },
    answer: {
        padding: 16,
    }
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
}

class Faqs extends React.Component<Props, State> {

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth={"lg"} style={{paddingLeft: 16, paddingRight: 16, marginBottom: 80,}} disableGutters>
                <Box className={classes.headline}>
                    <Typography gutterBottom variant="h1" component="h1" style={{ color: '#2d2d2d' }}>
                        Help us add your local spas and salons
                    </Typography>
                </Box>
                <Paper>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>What is Save Your Salons?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                                Save Your Salons is a platform created to help “flatten the curve” of lost income from COVID-19 by making it easy for customers, 
                                and friends to support their favorite beauty salons and spas. Save Your Salons allows you to purchase a gift card to be used at a later date.
                                <p>Sponsored by Glammly, an app that gives members access to the best beauty salons and day spas in Los Angeles.</p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>What is Glammly?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                            Glammly is an app that gives members access to the best beauty salons and day spas in Los Angeles. 
                            It was founded by a brother and sister team who wanted to create a better way for consumers to save on beauty treatments, 
                            while also ensuring salons and spas were being compensated the right amount of money for their services. 
                            Their app allows members to save 20% on the regular place, as well as save time with their booking platform. 
                            Unlike other apps, their pricing is in dollar amounts, not credits so you know how much you save and how much you spend. 
                            Transparency and fairness are at the forefront of their business.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>Why is Glammly doing this?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                            Glammly understands that beauty salons and day spas in Los Angeles need our financial support.
                            Even though we are unable to support them in person, we can still help by purchasing gift cards to be used at a later date. 
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>How can I redeem my Glammly credit?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                            In order to redeem your Glammly credit, you must follow the instructions sent by email from Glammly. Upon downloading and entering your details, your credit will appear.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>Why isn’t my favorite salon/spa on your site?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                                Please help us add your favorite day spas or salons <Link href={FIND_BUSINESS}  color="inherit"><b>HERE</b></Link>. 
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                            <Typography variant="h4" component="h4" className={classes.question}>How can you help?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography variant="body2" component="p" className={classes.answer} style={{fontWeight: "normal",}}>
                            Purchase a gift card to your favorite salon or spa through our site. If you do not see them listed here, <Link href={FIND_BUSINESS}  color="inherit"><b>click here</b></Link> to add them.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Paper>
            </Container>
        )
    }
}

export default withStyles(styles)(withRouter(Faqs));
