import { withStyles, WithStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import React from "react";
import Loader from 'react-loader-spinner';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import { functions } from '../../config';
import { FinishStripeConnectRequest } from '../../models/Company';
import { PAYMENT_REGISTRATION_END } from '../../constants/Routes';

const styles = {
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
    loading: boolean;
}

@inject('companyStore')
@observer
class StripeConnect extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        const { location: { search } } = this.props;
        console.log('Welcome to payment Finalized');
        const queryParams = queryString.parse(search);
        if (!queryParams.state || !queryParams.code) {
            throw new Error('Failed to connect no query params');
        } else {
            const finishConnect = functions.httpsCallable('sys_stripe_finish_connect');
            const data: FinishStripeConnectRequest = {
                code: queryParams.code as string,
                token: queryParams.state as string,
            };
            try {
                await finishConnect(data);
            } catch (err) {
                console.log('failed to finish connect flow', err);
                throw new Error('Failed to connect no query params');
            }
            this.setState({ loading: false });
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={'main'} style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Loader type="Oval" height={80} width={80} />
                </div>);
        } else {
            return (
                <Redirect to={PAYMENT_REGISTRATION_END}/>
            )
        }
    }
}

export default withStyles(styles)(withRouter(StripeConnect));
