import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/functions'
import "firebase/auth";

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

var master = firebase.initializeApp(config);
export const functions = firebase.functions(master);
export const db = firebase.firestore(master);
export const auth = firebase.auth(master);

