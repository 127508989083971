import { Container, withStyles, WithStyles, createStyles, Theme, Box, Typography, Button, Grid, Link } from '@material-ui/core';
import React from "react";
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import {SALONS, FIND_BUSINESS} from '../constants/Routes';
import to from '../components/to';
import BackgroundImage from '../assets/home@3x.png'
import BackgroundImageMobile from '../assets/home-mobile@3x.jpg'
import IconSearch from '../assets/icon-search@3x.png';
import IconGift from '../assets/icon-gift@3x.png';
import IconMoney from '../assets/icon-money@3x.png';
import IconShare from '../assets/icon-share@3x.png';
import IconBadge from '../assets/icon-badge@3x.png';

const styles = (theme: Theme) => createStyles({
    header: {
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        paddingTop: 80, 

        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.down('sm')]: {
            backgroundImage:`url(${BackgroundImageMobile})`,
            paddingBottom: 16,
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage:`url(${BackgroundImage})`,
            paddingBottom: 80,
        }
    },
    headerTitle: {
        color: '#2d2d2d',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '95%', 
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%', 
        }
    },
    headerSubtitle: {
        paddingTop: 16,
        paddingBottom: 32,
        lineHeight: 1.4,
        fontWeight: "normal",
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%', 
            fontSize: 22,
            marginBottom: 32
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%', 
        }
    },
    headerButton: {
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 32,
            marginRight: 32,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 300,
        }
    },
    sectionHeader: {
        color: '#2d2d2d',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    sectionDescription: {
        paddingTop: 24, 
        lineHeight: 1.5,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    gridIcon: {
        display: 'block',
        width: 72,
        height: 72,
        [theme.breakpoints.down('sm')]: {
            margin: `auto`,
        }
    },
    gridTitle: {
        marginTop: 16,
        height: 40,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    gridDescription: {
        marginTop: 16,
        color: '#2d2d2d',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        }
    }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface State {
}

class Home extends React.Component<Props, State> {

    render() {
        const { classes } = this.props;

        return (
            <Box style={{backgroundColor: 'white'}}>
            <Container maxWidth={"lg"} disableGutters>
                <Box className={classes.header}>
                    <Typography variant="h1" component="h1" className={classes.headerTitle}>
                        Help save your <Box component="span" style={{color: '#5c65df'}}>local salons</Box> and spas in <Box component="span" style={{color: '#5c65df'}}>Los Angeles</Box>
                    </Typography>
                    <Typography  variant="h2" component="h2" className={classes.headerSubtitle}>
                        Purchase a gift card, help save a local business and Glammly will match the amount with a Glammly credit (up to $50)
                    </Typography>
                    <Grid container direction="column" >
                        <Button size="large" color="primary" variant="contained" className={classes.headerButton} component={NavLink} {...to(`${SALONS}`)}>
                            Buy gift card
                        </Button>
                        <Button size="large" color="secondary" variant="outlined" className={classes.headerButton} href="http://itunes.apple.com/us/app/glammly/id1449886756?mt=8">
                            Glammly app
                        </Button>
                    </Grid>
                </Box>
                <Box style={{paddingTop: 40, paddingLeft: 16, paddingRight: 16}}>
                    <Typography variant="h1" component="h1" className={classes.sectionHeader}>
                        Our <Box component="span" style={{ color: '#5c65df'}}>mission</Box>
                    </Typography>
                    <Typography variant="h3" component="p" className={classes.sectionDescription} >
                        Your favorite beauty salons and spas are suffering as a&nbsp;result of the COVID-19 health crisis. 
                        Help us “flatten the curve” of lost income from COVID-19 by purchasing a&nbsp;gift card to your favorite salon or spa.
                    </Typography >
                    <Typography variant="h3" component="p" className={classes.sectionDescription} style={{paddingBottom: 32}}>
                        How many times has your hair salon saved you before a&nbsp;business meeting or date on a bad hair day? 
                        How many times has your nail artist fixed that nail that always chips? 
                        What about your eyebrow guru or spray tan artist who gives you that constant boost of confidence you need, or listens with an open ear? 
                        Let's return the favor so they feel taken care of, while they can’t take care of us.
                    </Typography>
                </Box>
                <Box style={{paddingTop: 16, paddingBottom: 80, paddingLeft: 16, paddingRight: 16}}>
                    <Typography variant="h1" component="h1" className={classes.sectionHeader}>How it works</Typography>
                    <Grid container
                        spacing={3}
                        justify="center"
                        lg={12} 
                        style={{ paddingTop: 45 }}>
                        <Grid item xs={12} sm={6} md={true} spacing={3}>
                            <img className={classes.gridIcon} alt="find a salon or spa" src={IconSearch} />
                            <Typography variant="h4" component="h4" className={classes.gridTitle}>Find a&nbsp;salon or spa</Typography>
                            <Typography variant="body1" component="p" className={classes.gridDescription}>Search for the salons and spas you love. If you cannot find your salon/spa, you can add it by <Link href={FIND_BUSINESS}  color="inherit"><b>clicking</b></Link>.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={true} spacing={3}>
                            <img className={classes.gridIcon} alt="find a salon or spa" src={IconGift} />
                            <Typography variant="h4" component="h4" className={classes.gridTitle}>Buy a&nbsp;gift card</Typography>
                            <Typography variant="body1" component="p" className={classes.gridDescription}>Purchase a&nbsp;gift card for yourself or a&nbsp;friend to a&nbsp;salon or spa.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={true} spacing={3}>
                            <img className={classes.gridIcon} alt="find a salon or spa" src={IconMoney} />
                            <Typography variant="h4" component="h4" className={classes.gridTitle}>We will match</Typography>
                            <Typography variant="body1" component="p" className={classes.gridDescription}>Glammly will put credit on your Glammly account for the amount you spent on a&nbsp;gift card (up to $50). 
                            <Link href="http://itunes.apple.com/us/app/glammly/id1449886756?mt=8" color="inherit"> <b>Explore their app</b></Link> and see what services you can use your credit towards.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={true} spacing={3}>
                            <img className={classes.gridIcon} alt="find a salon or spa" src={IconShare} />
                            <Typography variant="h4" component="h4" className={classes.gridTitle}>Share you kindness on&nbsp;social</Typography>
                            <Typography variant="body1" component="p" className={classes.gridDescription}>Tag @saveyoursalons @glammly and #SaveYourSalon and the spa or salon you supported to spread the word.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={true} spacing={3}>
                            <img className={classes.gridIcon} alt="find a salon or spa" src={IconBadge} />
                            <Typography variant="h4" component="h4" className={classes.gridTitle}>Challenge a&nbsp;friend</Typography>
                            <Typography variant="body1" component="p" className={classes.gridDescription}>
                                Tag friends and family, and challenge them to also help save their salons and spas. 
                                If you are also out of work, or just unable to purchase a&nbsp;gift card at this time, think about leaving a&nbsp;positive review. We could all use a&nbsp;little love right now.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </Box>
        )
    }
}

export default withStyles(styles)(withRouter(Home));
