import React from 'react';

import Header from './Header';
import Footer from './Footer';
import { Redirect, Route, Switch } from "react-router";
import SiteDescription from '../app/Home';
import FindBusiness from '../app/FindBusiness';
import AddBusiness from '../app/AddBusiness';
import {
  ADD_BUSINESS,
  FIND_BUSINESS,
  HOME,
  FAQS,
  SALONS,
  SALON,
  CONTACT,
  STRIPE_CONNECT,
  PAYMENT_REGISTRATION_START,
  COMPANY_DASHBOARD,
  SIGN_IN, SIGN_UP, ADMIN_DASHBOARD, SALON_CHECKOUT, BUSINESS_ADDED,
  PRIVACY_POLICY,
  PAYMENT_REGISTRATION_END,
  PAYMENT_SUCCESSFULL
} from '../constants/Routes';
import Faqs from '../app/Faqs';
import Salons from '../app/Salons';
import Salon from '../app/Salon';
import Contact from '../app/Contact';
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PrivacyPolicy from "../app/PrivacyPolicy";
import StripeConnect from '../app/payment/StripeConnect';
import protectedRoute from "./ProtectedRoute";
import CompanyDashboard from "../app/CompanyDashboard";
import AdminDashboard from "../app/AdminDashboard";
import CheckoutForm from "../app/payment/CheckoutForm";
import FindBusinessComplete from '../app/AddBusinessDone';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentRegistrationStart from '../app/payment/PaymentRegistrationStart';
import PaymentRegistrationEnd from '../app/payment/PaymentRegistrationEnd';
import PurchaseSuccessfull from '../app/payment/PurchaseSuccessfull';

function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Redirect path={"/home"} exact to={HOME} /> {/* SEO requires to have landing page at /  */}
        <Route exact strict path={HOME} sensitive={false} render={() => <SiteDescription />} />
        <Route exact strict path={FIND_BUSINESS} sensitive={false} render={() => <FindBusiness />} />
        <Route exact strict path={ADD_BUSINESS} sensitive={false} render={() => <AddBusiness />} />
        <Route exact strict path={BUSINESS_ADDED} sensitive={false} render={() => <FindBusinessComplete />} />
        <Route exact strict path={FAQS} sensitive={false} render={() => <Faqs />} />
        <Route exact strict path={SALONS} sensitive={false} render={() => <Salons />} />
        <Route exact strict path={SALON} sensitive={false} render={(props) => <Salon companyId={props.match.params.id} />} />
        <Route exact strict path={SALON_CHECKOUT} sensitive={false} render={(props) => (<ElementsConsumer>{({ elements, stripe }) => (<CheckoutForm elements={elements} stripe={stripe} companyId={props.match.params.id} />)}</ElementsConsumer>)} />
        <Route exact strict path={CONTACT} sensitive={false} render={() => <Contact />} />
        <Route exact strict path={PAYMENT_SUCCESSFULL} sensitive={false} render={() => <PurchaseSuccessfull />} />
        <Route exact strict path={STRIPE_CONNECT} sensitive={false} render={() => protectedRoute(StripeConnect)} />
        <Route exact strict path={PAYMENT_REGISTRATION_START} sensitive={false} render={() => protectedRoute(PaymentRegistrationStart)} />
        <Route exact strict path={PAYMENT_REGISTRATION_END} sensitive={false} render={() => protectedRoute(PaymentRegistrationEnd)} />
        <Route exact strict path={COMPANY_DASHBOARD} sensitive={false} render={() => protectedRoute(CompanyDashboard)} />
        <Route exact strict path={ADMIN_DASHBOARD} sensitive={false} render={() => protectedRoute(AdminDashboard)} />
        <Route exact strict path={SIGN_IN} component={SignIn} />
        <Route exact strict path={SIGN_UP} component={SignUp} />
        <Route exact strict path={PRIVACY_POLICY} component={PrivacyPolicy} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
