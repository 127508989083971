import React from 'react';
import {Button, Typography, withStyles, WithStyles, withWidth, WithWidthProps, ListItem, ListItemAvatar, Avatar, ListItemText, Hidden, Box} from '@material-ui/core';
import {RouteComponentProps, withRouter, NavLink} from 'react-router-dom';
import {Company} from "../models/Company";
import {SALONS} from '../constants/Routes';
import BackgroundImage from '../assets/home@3x.png';

const styles = {
    root: {
        margin: 10
    },
    content: {
        display: 'flex',
    },
    avatar: {
        width: 88,
        height: 88,
    },
    text: {
        marginLeft: 16,
        marginRight: 16
    },
}

interface CompanyCardProps extends WithStyles<typeof styles>, RouteComponentProps, WithWidthProps {
    company: Company,
    gService: google.maps.places.PlacesService,
}

interface CompanyCardState {
    photoUrl?: string;
}

class CompanyCard extends React.Component<CompanyCardProps, CompanyCardState> {

    constructor(props) {
        super(props);
        this.state = {
            photoUrl: ''
        }
    }

    componentDidMount() {
        
        var request = {
            placeId: this.props.company.placeId,
            fields: ['photos']
        };

        this.props.gService.getDetails(request, (result, status) => {
            const photoUrl = result?.photos && result?.photos[0]?.getUrl({maxHeight: 700, maxWidth: 1200});
            this.setState({
                photoUrl
            })
        });
    }

    render() {
        const { classes, company } = this.props;
        const photoUrl = this.state.photoUrl || `url(${BackgroundImage})`;
        return (
            <Box>
                <ListItem className={classes.content} button={true} component={NavLink} to={{ pathname: `${SALONS}/${company.id}`, state: { photoUrl } }}>
                    <ListItemAvatar>
                        <Avatar
                            className={classes.avatar}
                            variant="rounded"
                            alt={company.name}
                            src={photoUrl}
                        />
                    </ListItemAvatar>
                    <ListItemText className={classes.text}>
                        <Typography gutterBottom variant="h4" component="h4">
                            {company.name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {company.address}
                        </Typography>
                    </ListItemText>
                    <Hidden smDown>
                        <Button size="large" color="primary" variant="contained" style={{ minWidth: 120 }} component={NavLink} to={{ pathname: `${SALONS}/${company.id}`, state: { photoUrl } }}>
                            Buy gift card now
                        </Button>
                    </Hidden>
                </ListItem>
                <Hidden mdUp>
                    <Box style={{paddingLeft: 16, paddingRight: 16}}>
                        <Button size="large" color="primary" variant="contained" 
                            style={{ width: '100%', marginBottom: 8}} 
                            component={NavLink} to={{ pathname: `${SALONS}/${company.id}`, state: { photoUrl } }}>
                            Buy gift card now
                        </Button>
                    </Box>
                </Hidden>
            </Box>
        )
    }
}

export default withWidth()(withStyles(styles)(withRouter(CompanyCard)));
