import React from "react";
import {withStyles, WithStyles, Container, List, Typography, Box,Divider} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {inject, observer} from "mobx-react";
import {CompanyStore} from "../stores/CompanyStore";
import {Company} from "../models/Company";
import Card from '@material-ui/core/Card';
import CompanyCard from "../components/CompanyCard";

const styles = {
    headline: {
        marginTop: 32,
        padding: 10
    },
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    companyStore?: CompanyStore;
}

interface State {
    companies: Array<Company>
}

@inject('companyStore')
@observer
class Salons extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
        this.state = {
            companies: []
        }
    }

    private mapRef;

    private placesService: google.maps.places.PlacesService | null;

    async componentDidMount() {
        const {companyStore} = this.props;

        this.placesService = new google.maps.places.PlacesService(this.mapRef.current);
        // @ts-ignore
        const companies = await companyStore.getApprovedCompanies(true);
        this.setState({companies});
    }

    render() {
        const {companies} = this.state;
        const { classes } = this.props;

        return (
            <Container maxWidth={"lg"} style={{paddingLeft: 16, paddingRight: 16, marginBottom: 80,}} disableGutters>
                <div ref={this.mapRef}></div>
                <Box className={classes.headline}>
                    <Typography gutterBottom variant="h2" component="h2">
                        Support your local salons and spas in <Box component="span" style={{color: '#5c65df'}}>Los Angeles, CA</Box>
                    </Typography>
                    <Typography gutterBottom variant="h3" component="h3">
                        Business across all of LA, show in alphabetical order
                    </Typography>
                </Box>
                <Card>
                    <List>
                        {companies.map(c => <Box key={c.id}><CompanyCard gService={this.placesService} company={c} /><Divider variant="middle" component="li" /></Box>)}
                    </List>
                </Card>
            </Container>
        )
    }
}

export default withStyles(styles)(withRouter(Salons));
